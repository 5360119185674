/* src/App.js */
import React from 'react'
import { Amplify } from 'aws-amplify'
import logo from "./logo.png"
import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const App = () => {

  return (
    <div style={styles.container}>
     <img style={styles.logo} src={logo} alt="logo" />
    </div>
  )
}

const styles = {
  container: { backgroundColor: '#000000', display: 'flex', height: '100vh', width: '100vw', alignItems: 'center', justifyContent: 'center' },
  logo: {maxWidth: '100%', objectFit: 'scale-down'}
}

export default App